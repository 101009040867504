<!-- 个人中心 => 基本信息 -->
<template>
    <div class="business">
        <div class="identity">
            <div class="identity_title">
                <span class="iconfont icon-sfrz-icon"></span>
                <span>基本信息</span>
            </div>
            <div class="identity_form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="姓名" prop="user_name">
                        <el-input v-model.trim="ruleForm.user_name" maxlength="20" :disabled="is_sign_type" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="user_idno">
                        <el-input v-model.trim="ruleForm.user_idno" maxlength="18" :disabled="is_sign_type" placeholder="请输入您的身份证号码"></el-input>
                    </el-form-item>
                    <el-form-item label="客户本人实名认证的手机号码" prop="mobile">
                        <el-input v-model.trim="ruleForm.mobile" maxlength="11" placeholder="请输入您实名认证的手机号码"></el-input>
                    </el-form-item>
                </el-form>
                <button class="my_button identity_form_next" @click="evNext">下一步</button>
            </div>
        </div>
        <!-- loading -->
        <Loading :isLoading="loading" :text="loadingText" />
    </div>
</template>

<script>
// 接口
import { postAuthentication, GetResult } from '@/api'
// 组件
import Loading from '@/components/Loading'

export default {
    data () {
        return {
            // 用户 ID
            third_id: localStorage.getItem('thirdId'),
            // 参数
            ruleForm: {},
            // loading
            loading: false,
            loadingText: '',
            // 认证状态
            is_sign_type: false,
            // 计时器
            timer: null,
            // 校验
            rules: {
                user_name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                user_idno: [
                    { required: true, message: '请输入您的身份证号码', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入您实名认证的手机号码', trigger: 'blur' },
                    { min: 11, message: '手机号码不正确', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        // 身份认证(接口)
        apiAuthentication: function () {
            postAuthentication({ ...this.ruleForm, backUrl: `http://${location.host}/tips` })
                .then(({ code, data, msg }) => {
                    if (code === 1000) {
                        location.href = data.startFaceUrl
                        return
                    }
                    this.$message.error(msg)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        // 查询结果(接口)
        apiResult: function () {
            GetResult()
                .then(({ code, data }) => {
                    if (code === 1000) {
                        clearInterval(this.timer)
                        const { status, user_name, user_idno, mobile } = data
                        if (status === 1) {
                            Object.assign(this, {
                                'is_sign_type': true,
                                'loading': false,
                                'timer': null
                            })
                            Object.assign(this.ruleForm, {
                                edit_id: this.$route.query.edit_id || '',
                                user_name,
                                user_idno,
                                mobile
                            })
                        }
                    }
                })
        },
        // 查询结果
        closeDialog: function () {
            let count = 1
            this.loading = true
            this.loadingText = `正在第${count}次查询后台结果...`
            this.timer = setInterval(() => {
                if (count < 5) {
                    ++count
                    this.loadingText = `正在第${count}次查询后台结果...`
                    this.apiResult()
                } else {
                    clearInterval(this.timer)
                    this.loading = false
                    this.$message.error('未查询到结果, 请重新填写...')
                }
            }, 3000)
        },
        // 进入资料上传页面
        goToMaterialView: function () {
            this.$router.push({
                'name': 'CusMaterial',
                'query': { ...this.ruleForm, third_id: this.third_id }
            })
        },
        // 下一步 (按钮)
        evNext: function () {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    // 身份认证
                    Object.assign(this, {
                        'loading': true,
                        'loadingText': '正在跳转认证页面...'
                    })
                    this.is_sign_type ? this.goToMaterialView() : this.apiAuthentication()
                }
            })
        }
    },
    mounted () {
        // 判断是否是认证成功之后返回该页
        const { code } = this.$route.query
        if (code === '0') this.closeDialog()
    },
    components: {
        Loading
    }
}
</script>

<style lang="scss" scoped>
$fontSize: 32px;

.identity {
    display: flex;
    flex-direction: column;
    padding: 50px 24px 100px;
    height: 100vh;
    font-size: $fontSize;
    background: url("../../assets/kh-bg@2x.png") no-repeat;
    background-size: 100%;

    &_title {
        margin: 0 auto 20px;
        width: 272px;
        height: 84px;
        line-height: 84px;
        color: #fff;
        font-size: 32px;
        border-radius: 12px;
        background: #FFC060;
        text-align: center;

        .iconfont {
            margin-right: 10px;
            line-height: 36px;
            font-size: 36px;
        }
    }

    &_form {
        position: relative;
        width: 100%;
        flex-grow: 1;
        min-height: 960px;
        overflow: hidden;
        background: #fff;
        border-radius: 40px 40px 0 0;
        box-shadow: 0 0 20px rgba(87, 95, 102, 0.16);

        &_next {
            position: absolute;
            bottom: 54px;
            left: 50%;
            margin-left: -307px;
            letter-spacing: 10px;
            font-weight: 500;
            background: linear-gradient(90deg, #F2970C, #FEC56E);
        }
    }
}
</style>

<style lang="scss" scoped>
.demo-ruleForm {
    padding: 44px;

    /deep/.el-form-item {
        display: block;
        margin: 0 0 44px;

        .el-form-item__label {
            display: block;
            margin: 16px 0;
            width: 100% !important;
            line-height: normal;
            color: #333;
            font-size: 30px;
            text-align: left;
            font-weight: normal;

            &::before {
                display: inline-block;
                content: '';
                margin-right: 12px;
                width: 8px;
                height: 28px;
                background: #F2970C;
                border-radius: 0 8px 8px 0;
                overflow: hidden;
            }
        }

        .el-form-item__content {
            line-height: normal;

            .el-input {
                .el-input__inner {
                    padding: 0 15px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 28px;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 1px solid #EBEBEB;
                }
            }
        }
    }
}

/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
    content: '*';
    color: var(--el-color-danger);
    margin-left: 4px;
}
</style>
